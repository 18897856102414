<template>
  <div id="page-user-view">
    <vs-row
      :v-can="permissions.add"
      style="padding-bottom: 30px"
    >
      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="12"
      >
        <vs-button
          icon-pack="feather"
          icon="icon-plus"
          class="mr-4"
          @click="createRecord"
        >
          إضافة مشروع جديد
        </vs-button>
      </vs-col>
    </vs-row>
    <div
      id="user-data"
      v-if="projects"
    >
      <div
        v-for="(project,index) in projects"
        :key="index"
      >
        <vx-card class="mb-base">
          <div class="vx-row">
            <div
              class="vx-col"
              id="avatar-col"
            >
              <div class="img-container mb-4">
                <img
                  style="object-fit: cover; max-height:150px"
                  v-lazy="project.logo_url"
                  class="rounded w-full"
                >
              </div>
            </div>

            <div
              class="vx-col flex-1"
              id="account-info-col-1"
            >
              <table>
                <tr>
                  <td class="font-semibold">
                    الاسم
                  </td>
                  <td>{{ project.title }}</td>
                </tr>
                <tr/>
                <tr>
                  <td class="font-semibold">
                    الوصف
                  </td>
                  <td>{{ project.desc }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    اللون
                  </td>
                  <td>
                    <vs-row>
                      <span class="pr-2">{{ project.color }}</span>
                      <vs-card
                        :style="{'background-color':project.color,'color':project.color, 'height': '20px', 'width':'20px'} "
                      />
                    </vs-row>
                  </td>
                </tr>
              </table>
            </div>
            <div
              class="vx-col flex-1"
              id="account-info-col-2"
              style="padding-top: 35px"
            >
              <table>
                <tr>
                  <td
                    class="font-semibold"
                    :href="project.excel_url"
                  >
                    ملف الاكسل
                  </td>
                  <td><a :href="project.excel_url">{{ getExcelFileName(project.excel_url) }}</a></td>
                  <feather-icon icon="FileIcon"/>
                </tr>
              </table>
            </div>
            <div
              class="vx-col w-full flex"
              id="account-manage-buttons"
            >
              <vs-button
                v-can="permissions.edit"
                icon-pack="feather"
                icon="icon-edit"
                class="mr-4"
                @click.prevent="editRecord(project,index)"
              >
                تعديل
              </vs-button>
              <vs-button
                v-can="permissions.delete"
                type="border"
                color="danger"
                icon-pack="feather"
                icon="icon-trash"
                @click="confirmDeleteRecord(project.id,index)"
              >
                حذف
              </vs-button>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <tm-popup
      :submit="onSubmit"
      :title="dialogTitle"
      ref="popup"
    >
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-row
            vs-align="center"
            vs-type="flex"
            vs-justify="center"
            vs-w="12"
          >
            <image-uploader
              @fileUploaded="fileUploaded"
              :image-preview="imagePreview"
              :is-image-required="true"
              ref="imageUploader"
            />
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="8"
              class="mb-5"
            >
              <tm-text-area
                v-model="barcodeProject.title"
                name="الاسم"
                validation-rules="required"
              />
            </vs-col>
            <vs-col
              vs-justify="center"
              vs-align="center"
              vs-w="8"
              class="mb-5"
            >
              <color-picker
                :color="defaultColor"
                ref="colorPicker"
                v-model="barcodeProject.color"
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="8"
              class="mb-5"
            >
              <tm-text-area
                v-model="barcodeProject.desc"
                validation-rules="required"
                name="الوصف"
              />
            </vs-col>
            <vs-col
              vs-justify="center"
              vs-align="center"
              vs-w="8"
              class="mb-5"
            >
              <ValidationProvider
                name="ملف الإكسل"
                rules="required"
                v-slot="{ errors }"
              >
                <vs-input
                  placeholder="رفع ملف اكسل"
                  icon-pack="feather"
                  icon="icon-upload"
                  icon-after
                  vs-icon-after="true"
                  @click="$refs.file.click()"
                  v-model="fileName"
                  class="w-full"
                  readonly
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <input
                type="file"
                hidden
                ref="file"
                accept="csv/xslx"
                @change="onchange($event)"
              >
            </vs-col>
            <vs-col
              vs-justify="center"
              vs-align="center"
              vs-w="8"
              class="mb-5"
            >
              <vs-row>
                <div
                  class="p-2"
                  v-if="typeof this.barcodeProject.logo_url == 'string'"
                >
                  <vs-col
                    vs-justify="center"
                    vs-align="center"
                    class="pb-2"
                  >
                    <a :href="barcodeProject.excel_url">عرض ملف الإكسل</a>
                  </vs-col>
                  <vs-col
                    vs-justify="cent"
                    vs-align="left"
                  >
                    <a :href="barcodeProject.excel_url">{{ getExcelFileName(barcodeProject.excel_url) }}</a>
                  </vs-col>
                </div>
              </vs-row>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
    </tm-popup>
  </div>
</template>

<script>
import {RepositoryFactory} from "../barcode-repositories/barcodeRepositoryFactory";
import TmPopup from "@/app/shared/shared-components/TmPopup";
import TmTextArea from "@/app/shared/shared-components/TmTextArea";
import utilities from '@/app/shared/utilities/index';
import imageUploader from '@/app/shared/shared-components/ImageUploader';
import colorPicker from "@/app/shared/shared-components/colorPicker";

const barcodeProjectsRepo = RepositoryFactory.get("barcodeProjectsRepository");

export default {
  components: {
    TmPopup,
    TmTextArea,
    imageUploader,
    colorPicker
  },
  data() {
    return {
      barcodeProject: {
        id: '',
        title: '',
        desc: '',
        color: '',
        logo_url: '',
        excel_url: ''
      },
      updatedProjectIndex: '',
      deletedProjectIndex: '',
      dialogTitle: '',
      imagePreview: null,
      projects: [
        {
          id: '',
          title: '',
          desc: '',
          color: '',
          logo_url: '',
          excel_url: ''
        }
      ],
      popupActive: false,
      defaultColor: '#4B364E',
      permissions: {
        add: 'store_barcode_projects',
        delete: 'destroy_barcode_projects',
        edit: 'update_barcode_projects'
      },
      fileName: ''
    };
  },
  watch: {
    fileName(val) {
      if (val) {
        this.fileName = val;
      }
    }
  },
  methods: {
    fetchAllProjects() {
      barcodeProjectsRepo.fetchAllProjects().then((data) => {
        this.projects = data;
      });
    },
    getExcelFileName(url) {
      return url.split('/').pop();
    },
    fileUploaded(file) {
      this.barcodeProject.logo_url = file;
    },
    openPopup() {
      this.$refs.colorPicker.setColor(this.defaultColor);
      this.$refs.popup.open();
    },
    editRecord(barcodeProject, index) {
      this.defaultColor = barcodeProject.color;
      this.updatedProjectIndex = index;
      this.dialogTitle = 'تعديل المشروع';
      this.fileName = this.getExcelFileName(barcodeProject.excel_url);
      Object.assign(this.barcodeProject, barcodeProject);
      this.imagePreview = this.barcodeProject.logo_url;
      this.openPopup();
    },
    checkColor(color) {
      if (color.startsWith("rgba"))
        this.barcodeProject.color = utilities.rgba2hex(color);
    },
    onSubmit() {
      if (this.barcodeProject.color === null)
        this.barcodeProject.color = this.defaultColor;
      this.checkColor(this.barcodeProject.color);
      if (typeof this.barcodeProject.logo_url == "string")
        delete this.barcodeProject.logo_url;
      if (this.barcodeProject.id !== null) {
        this.updateProject(this.barcodeProject);
      } else {
        this.storeProject(this.barcodeProject);
      }
    },
    createRecord() {
      utilities.initObjectMembers(this.barcodeProject);
      this.defaultColor = '#4B364E';
      this.fileName = '';
      this.$refs.imageUploader.clearImage();
      this.imagePreview = null;
      this.dialogTitle = 'إضافة مشروع';
      this.openPopup();
    },
    confirmDeleteRecord(projectId, index) {
      this.deletedProjectIndex = index;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'هل أنت متأكد من الحذف؟',
        text: `في حال الموافقة, لن تتمكن من التراجع!`,
        accept: this.deleteProject,
        acceptText: 'موافق',
        parameters: [projectId]
      });
    },
    storeProject(barcodeProject) {
      barcodeProjectsRepo.storeProject(this.$objectToFD(barcodeProject)).then((response) => {
        this.$refs.popup.endLoading();
        if (response.code === 200) {
          this.$refs.popup.close();
          this.projects.push(response.data);
        }
      });
    },
    deleteProject(projectId) {
      barcodeProjectsRepo.deleteProject(projectId).then((response) => {
        if (response.code === 200) {
          this.projects.splice(this.deletedProjectIndex, 1);
        }
      });
    },
    updateProject(barcodeProject) {
      if (typeof this.barcodeProject.excel_url == "string") {
        delete this.barcodeProject.excel_url;
      }
      barcodeProjectsRepo.updateProject(this.$objectToFD(barcodeProject), barcodeProject.id).then((response) => {
        this.$refs.popup.endLoading();
        if (response.code === 200) {
          this.$refs.popup.close();
          this.projects.splice(this.updatedProjectIndex, 1);
          this.projects = [...this.projects.slice(0, this.updatedProjectIndex),
            response.data,
            ...this.projects.slice(this.updatedProjectIndex)];
        }
      });
    },
    onchange(event) {
      this.file = event.target.files[0];
      this.barcodeProject.excel_url = this.file;
      this.fileName = this.file.name;
    }
  },
  created() {
    this.fetchAllProjects();
  }
};
</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }
}
</style>
