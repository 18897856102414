import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.barcodeApiBaseUrl}projects`;
export default {
  fetchAllProjects() {
    return httpClient.get(URL)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },
  storeProject(project) {
    return httpClient.post(URL, project)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  updateProject(project, id) {
    const url = URL + `/${id}?_method=PUT`;
    return httpClient.post(url, project)
      .then(res => res.data)
      .catch(error => console.log(error));
  },

  deleteProject(id) {
    return httpClient.delete(`${URL}/${id}`)
      .then(res => res.data)
      .catch(error => console.log(error));
  }
};
