import barcodeProjectsRepository from "./barcodeProjectsRepository";


export const repositories = {
  barcodeProjectsRepository

};

export const RepositoryFactory = {
  get: name => repositories[name]
};
